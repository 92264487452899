.textAwaitSolutions {
    text-align: center;
    margin-bottom: 1vh;
}

.awaitAllocationResultsContainer {
    position: relative;
    height: 90vh;
}

.innerAwaitAllocationResultsContainer {
    position: absolute;
    top: 45%;
    transform: translateY(-45%);
}

.indexedStep {
    color: white;
    width: 2.5vh;
    height: 2.5vh;
    font-size: 0.8em;
    background-color: var(--secondary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.indexedStep.accomplished {
    background-color: var(--primary-color);
}

.indexedStep.errorOccured {
    background-color: #ff8282;
}

.indexedStep.accomplished.errorOccured {
    background-color: #ff0000;
}