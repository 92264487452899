.userListTabsRow .nav-tabs .nav-link {
    color: var(--primary-color);
}
.td-align-center {
    text-align: center;
}

.justify-content-center {
    justify-items: center;
}

.whitespace-nowrap {
    white-space: "nowrap";
}