.rightSection {
    height: 100vh;
    background-color: var(--tertiary-color) !important;
}

.imgRightSection {
    width: 100%;
    height: 100%;
}

.leftSection {
    margin: auto 10% !important;
}

.errorTextRow {
    color: red;
    font-size: 1em;
    text-align: center;
}

.homeLogo {
    height: 10vh;
}

.loginButton {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.loginButton:hover {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
}

.registerText {
    text-align: center;
}

.forgotPassword {
    text-align: right;
}