.defaultInput.withIcon {
    border-left: none !important;
}

.defaultInput:focus {
    box-shadow:none !important;
    border-color: #DEE2E6 !important;
}

.inputIcon {
    background-color: white !important;
}

.inputIcon.disabledIcon {
    background-color: #E9ECEF !important;
}