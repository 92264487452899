.userDetailsBackButton {
    padding-left: 2vw !important;
    padding-right: 2vw !important;
    margin: 1em 0 1em 0;
    border: 1px solid #000 !important;
    background-color: white !important;
    font-weight: bold !important;
}

.userDetailsBackButton:hover {
    background-color: #000 !important;
    color: white !important;
}

.userDetailsValidateButton {
    padding-left: 2vw !important;
    padding-right: 2vw !important;
    margin: 1em 0 1em 0;
    border: 1px solid var(--primary-color) !important;
    background-color: var(--primary-color) !important;
    font-weight: bold !important;
}

.userDetailsValidateButton:hover {
    background-color: white !important;
    color: var(--primary-color) !important;
}

.userDetailsCheckbox .form-check-label {
    font-weight: bold;
    color: rgb(95, 95, 95);
}

.userDetailsCheckbox .form-check-input:checked {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}