.allocationHistoryItem[aria-selected="true"] {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.allocationHistoryItem {
    margin-bottom: 12px;
}

.allocationStatusBadge-red {
    background-color: red !important;
    color: white !important;
    padding: 4px;
    border-radius: 7px;
    font-size: 10px;
    text-align: center;
}

.allocationStatusBadge-green {
    background-color: green !important;
    color: white !important;
    padding: 4px;
    border-radius: 7px;
    font-size: 10px;
    text-align: center;
}

.allocationStatusBadge-blue {
    background-color: blue !important;
    color: white !important;
    padding: 4px;
    border-radius: 7px;
    font-size: 10px;
    text-align: center;
}

.allocationStatusBadge-orange {
    background-color: orange !important;
    color: white !important;
    padding: 4px;
    border-radius: 7px;
    font-size: 10px;
    text-align: center;
}