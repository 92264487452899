.dayCol {
    font-weight: bold;
    text-align: center;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    padding: 0.5em;
}

.dayCol.selected {
    background-color: var(--primary-color);
    color: white;

}

.dayCol:hover {
    background-color: var(--secondary-color);
    color: white;
    cursor: pointer;
}