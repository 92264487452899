.jamNavBar .navbar-brand {
    color: white;
}

.jamNavBar .navbar-nav .nav-link {
    color: white;
}

.jamNavBar .navbar-text {
    color: white;
}

.navbar {
    background-color: #151A1F;
}