.react-international-phone-input-container {
    width: 100%;
}

.react-international-phone-input {
    width: 100%;
    font-family: 'Inter' !important;
    font-size: 16px !important;
}

/*.react-international-phone-input:focus {
    border-color: var(--primary-color) !important;
}*/

.react-international-phone-country-selector-button {
    border-right: none !important;
    padding-left: 10px !important;
}