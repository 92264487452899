.inputIcon {
    padding-left: 12px;
}

.requiredStar {
    color: red;
}

.inputTitleText {
    color: rgb(95, 95, 95);
}

.inputTitleTextCheckbox {
    margin-left: 10px;
    margin-right: 0px !important;
}