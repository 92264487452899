.solutionLoader {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination .page-link {
    font-weight: 700;
    font-size: 1em;
    color: var(--secondary-color);
    background-color: white;
    text-align: center;
}

.pagination .page-link:hover {
    color: var(--primary-color);
    background-color: var(--secondary-color);
    border-bottom: 1px solid var(--primary-color);
}

.page-item.active .page-link {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border: 1px solid #dee2e6;
    border-bottom: 1px solid var(--primary-color);
}

.solutionPresentation {
    border: 1px solid #00000021;
    border-radius: 6px;
}

.kpiPaginationRoot {
    width: 100%;
    display: flex !important;
}

.kpiPaginationCol {
    font-size: 0.8rem;
}

.kpiPaginationItem {
    overflow: hidden;
    flex-grow: 1;
    flex-basis:0;
}

.kpiPaginationItem span {
    text-overflow: ellipsis;
}

.solutionPresentationCol {
    display: flex;
    flex-direction: column;
}

.solutionPresentationContent {
    /*max-height: 47em;*/
    overflow-y: auto;
    padding-top: 1em;
    padding-bottom: 0;
    flex-grow: 1;
}

.solutionPresentationContent .supportRadio {
    margin-left: 5px;
    border-width: medium;
    font-size: 1.2em;
    margin-top: -1px;
}

.solutionPresentationContent .supportRadio label {
    font-size: 0.9em;
    margin-left: 0.3vw;
}

.solutionPresentationContent .supportRadio .form-check-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.solutionPresentationContent .pagination .page-item {
    width: 100%;
}

.supportListItem {
    margin-top: 1px;
}

.solutionTitle {
    font-weight: bold;
    font-size: 1.6em;
    color: var(--primary-color);
}

.solutionKPIs {
    margin-top: 1em !important;
}

.briefIcon{
    width: 2em !important;
    height: 2em !important;
}

.vueGrille {
    margin-top: 1em !important;
}

.solutionSubTitle {
    font-weight: bold;
    font-size: 1.3em;
    color: #000000;
}

.solutionCalendar {
    background-color: #F9F9F9;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding-right: 0;
}

.solutionTimeTable {
    width: 100%;
    overflow-x: scroll;
}

.colored-circle-timetable {
    display: inline-block;
    border-radius: 50%;
    border-width: 0px;
    height: 1.8em;
    width: 1.8em;
    text-align: center;
    vertical-align: middle;
    line-height: 1.7em;
}

.colored-circle-timetable-text {
    font-weight: bold;
    color:white;
}

.durationLegend {
    margin-left: 0.5vw;
}

.colored-circle-timetable-legend {
    font-weight: bold;
    color:black;
}

.colored-circle-timetable-legend-value {
    margin-left: 5px;
    font-size: 1em;
    font-weight: normal;
}

.black {
    background-color: #000000;
}

.blue {
    background-color: #2B6FF4;
}

.green {
    background-color: #57B904;
}

.red {
    background-color: #FF0000;
}

.yellow {
    background-color: #FFFF00;
}

.orange {
    background-color: #FF8312;
}

.purple {
    background-color: #800080;
}

.pink {
    background-color: #FFC0CB;
}

.brown {
    background-color: #A52A2A;
}

.grey {
    background-color: #bdbdbd;
}

.blue-text {
    color: #2B6FF4;
}

.green-text {
    color: #57B904;
}

.red-text {
    color: #FF0000;
}

.yellow-text {
    color: #FFFF00;
}

.orange-text {
    color: #FF8312;
}

.purple-text {
    color: #800080;
}

.pink-text {
    color: #FFC0CB;
}

.brown-text {
    color: #A52A2A;
}

.grey-text {
    color: #bdbdbd;
}

.tooltipTitle {
    font-weight: bold;
}

.verticalLine {
    color: gray;
    font-weight: normal;
}

.timetable-config-header {
    z-index: 2;
    background-color: #F9F9F9;
    position: sticky;
    top: 0;
}

.legend {
    display: flex;
    align-items: center;
}

.colCheckbox {
    padding: 0 !important;
    line-height: 1.5;
}

.supportBadgeLabel {
    margin-left: 0.5vw;
    font-weight: normal;
    font-size: 1.05em;
}

.colCheckbox .form-check-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.timetable-wrapper {
    max-height: 43em;
    overflow-y: scroll;
}

.timetable {
    table-layout: fixed;
    border-collapse: collapse;
    padding-right: 10px;
    width: 100%;
}

.timetable th {
    text-align: center;
    border: none;
    z-index: 3;
    background-color: #F9F9F9;
    width: 2.36em;
    height: 2.36em;
    max-width: 2.36em;
    max-height: 2.36em;
    vertical-align: bottom;
    overflow: visible;
    white-space: nowrap;
}

.timetable td {
    border: 1px solid #ccc;
    /*padding: 5px;*/
    text-align: center;
    width: 2.36em !important;
    height: 2.36em;
    max-width: 2.36em !important;
    max-height: 2.36em;
}

.timetable th:first-child,
.timetable td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: #F9F9F9;
    border: none;
}

.timetable .time-cell {
    position: relative;
    margin-left: -10px;
}

.timetable .time-cell span {
    position: relative;
    top: -1.1em;
    background-color: #F9F9F9;
}

.timetable-title {
    font-size: 24px;
}

.timetable-config-header {
    z-index: 2;
    background-color: #F9F9F9;
    position: sticky;
    top: 0;
}

.header-dates {
    position: sticky;
    top: 0;
}

.dayOfWeek {
    font-weight: normal;
}

#tooltip > .tooltip-inner {
    background-color: rgb(255, 255, 255);
    color:black;
    box-shadow: 0 4px 6px gray;
    border-radius: 0;
    text-align: left;
    font-size: 1em;
    white-space: nowrap;
    max-width: none; 
}

.colored-circle {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -2px;
    border-radius: 50%;
    border-width: 0px;
    height: 1.2em;
    width: 1.2em;
    top: 10%;
    -ms-transform: translateY(10%);
    transform: translateY(10%);
}

.selectSolutionButton {
    padding-left: 5em !important;
    padding-right: 5em !important;
    margin: 1em 0 1em 0;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important
}

.selectSolutionButton:hover {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important
}