.choicePicker .form-control {
    border-left: none !important;
}

.choicePicker .rbt-token {
    color: white;
    background-color: var(--primary-color);
}

.choicePicker .rbt-aux span.rbt-close-content {
    display: none !important;
}

.inputIconSelect {
    padding-left: 12px;
}