.sectionContinueButton {
    padding-left: 5em !important;
    padding-right: 5em !important;
    margin: 1em 0 1em 0;
}

@keyframes paddingAnimation {
    0% { padding: initial; }
    100% { padding: 0; }
}

.sectionContent.collapsed {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: max-height .25s, padding 0s .208s;
    transition: max-height .25s, padding 0s .208s;
    padding: 0;
}

.sectionContent.expanded {
    overflow: auto;
    max-height: 110vh;
    -webkit-transition: max-height .25s;
    transition: max-height .25s;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}

.lastSectionCard {
    margin-bottom: 10vh;
}

.sectionCard .card-header {
    font-size: 20px;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: none;
}