.selectBriefCard {
    width: 15.4em;
    height: 5em !important;
    font-weight: bold;
    margin-bottom: 1em;
    text-align: center;
}

.selectBriefCard.selectedCard {
    background-color: var(--primary-color);
    color: white;
}

.selectBriefCard:hover {
    border-color: var(--primary-color);
    cursor: pointer;
}

.selectBriefCardTitle {
    margin-top: 0.5em !important;
}

.selectBriefContainer {
    display: flex !important;
    justify-content: left;
    flex-wrap: wrap;
    gap: 1em;
}