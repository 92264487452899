.briefBackButton {
    padding-left: 5em !important;
    padding-right: 5em !important;
    margin: 1em 0 1em 0;
    border: 1px solid #000 !important;
    background-color: white !important;
    font-weight: bold !important;
}

.briefBackButton:hover {
    background-color: #000 !important;
    color: white !important;
}

.directAdvertiserCheck .form-check-label {
    font-weight: bold;
    color: rgb(95, 95, 95);
}

.directAdvertiserCheck .form-check-input:checked {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.briefInputRow {
    margin-bottom: 0.4em;
}