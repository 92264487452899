/* styles.css */
.success .container-fluid {
    display: flex;
    height: 100vh;
}

.success-icon {
    color: var(--primary-color);
    font-size: 4rem;
}

.success-message {
    margin-top: 20px;
    font-size: 1.5rem;
}
