.selectMediaCard {
    width: 12em;
    height: 5em !important;
    font-weight: bold;
    margin-bottom: 1em;
    text-align: center;
}

.selectMediaCard.selectedCard {
    background-color: var(--primary-color);
    color: white;
}

.selectMediaCard:hover {
    border-color: var(--primary-color);
    cursor: pointer;
}

.selectMediaCardTitle {
    margin-top: 0.5em !important;
}

.selectMediaContainer {
    display: flex !important;
    justify-content: left;
    flex-wrap: wrap;
    gap: 1em;
}