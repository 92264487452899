.jamtab.nav-link {
    color: grey;

}

.jamtab.nav-link:hover {
    color: black;
    
}

.jamtab.nav-link.active {
    color: black;
    /* font-weight: bold; */
}