.table.dataTableV2 {
    table-layout: auto;
    border-collapse: separate;
    border-spacing: 0 0.5em;
    margin: 0 0 0 0;
}

.dataTableV2 .dataRow {
    width: 100%;
    background-color: var(--tertiary-color) !important;
}

.dataRow td ::placeholder {
    color: gray;
    text-align: center !important;
}

.dataRow td input:placeholder-shown {
    text-align: center !important;
}

.dataTableV2 .dataRow>td {
    padding-bottom: 1em;
}

.dataRow td:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.dataRow .lastDataCell {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.dataTableV2 .dataTableV2HeaderRow {
    border:none !important;
}

.dataTableV2 .rowAddButton {
    background-color: transparent !important;
}

.dataTableV2 th {
    border: none !important;
    text-align: center;
    font-size: 0.9rem;
    vertical-align: middle;
    color: gray !important;
    font-weight: normal;
}

.dataTableV2 td {
    border: none !important;
    text-align: center;
    vertical-align: middle;
    background-color: transparent !important;
}

.dataTableV2 .percentageInput {
    width: 100%;
    border: none;
    background-color: transparent;
    text-align: center;
    font-size: 0.9rem;
    width: 4rem !important;
    min-width: 4rem;
}

.dataTableV2 td input, .dataTableV2 td select {
    width: 100%;
    border: none;
    background-color: transparent;
    text-align: center;
    font-size: 0.9rem;
    min-width: 6rem;
    margin: auto;
}

.dataTableV2 td .input-group > .form-control {
    max-width: -moz-fit-content;
    max-width: fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.dataTableV2 td select {
    min-width: 9rem;
}

.dataTableV2 .buttonDelete {
    background: none;
    border: none;
}

.dataTableV2 .deleteButtonCell {
    width: 5em;
}

.dataTableV2 .cellDeleteButton {
    background-color: white !important;
    text-align: end !important;
}

.dataTableV2 .buttonDelete:hover {
    background-color: red;
    border-color: red;
}

.dataTableV2 .buttonDelete:active {
    background-color: red !important;
    border-color: red !important;
}

.dataTableV2 .buttonDelete:focus-visible {
    background-color: red !important;
    border-color: red !important;
}

.dataTableV2 .buttonDelete:hover .buttonDeleteIcon {
    color: white !important;
}


.dataTableV2 .buttonAdd {
    background-color: var(--primary-color);
    border: none;
}

.dataTableV2 .buttonAdd:hover {
    background-color: var(--secondary-color) !important;
    border: none;
}

.dataTableV2 .buttonAdd:active {
    background-color: var(--secondary-color) !important;
    border: none;
}

.dataTableV2 .buttonAdd:focus-visible {
    background-color: var(--secondary-color) !important;
    border: none;
}