.leftSection {
    margin: auto 10% !important;
}

.formRequiredRow {
    color: red;
    font-size: 0.8em;
}

.errorTextRow {
    color: red;
    font-size: 1em;
    text-align: center;
}

.rightSection {
    height: 100vh;
    background-color: var(--tertiary-color) !important;
}

.homeLeftCol {
    text-align: center;
}

.homeLogo {
    height: 10vh;
}