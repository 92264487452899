.mainContainer {
    margin-top: 1em;
}

.categoryTitle {
    color: var(--primary-color);
    font-size: 14px;
}

.sectionCard {
    margin-top: 2em;
}

.footer {
    background-color: white !important;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
}

.pageNextButton {
    padding-left: 5vw !important;
    padding-right: 5vw !important;
    margin: 1em 0 1em 0;
}

.fullPageLoader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centeredLoaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.titlePageLoader {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.floatingLocaleSelector {
    position:absolute !important;
    top: 1vh;
    right: 1vw;
}