@import url('https://fonts.googleapis.com/css?family=Inter');

.dateInput {
    border-left: none !important;
}

.daterangepicker {
    font-family: 'Inter' !important;
}

.daterangepicker td.in-range {
    background-color: var(--secondary-color);
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: var(--primary-color);
}