.formatPicker .form-control {
    border-left: none !important;
}

.formatPicker .rbt-token {
    color: white;
    background-color: var(--primary-color);
}

.formatPicker .rbt-close-content {
    display: none !important;
}